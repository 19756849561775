<template>
	<form :class="searchForm" @submit.prevent="$emit('submit', $event)">
		<div class="search-block">
			<b-form-input
				ref="input"
				v-model="modelValue"
				v-hotkey="{'/': {keyup: focus}}"
				type="text"
				:placeholder="placeholder"
				:class="inputClass"
			/>
			<button v-if="iconButton" type="submit" class="search-submit icon-search" />
		</div>
	</form>
</template>

<script>
export default {
	name: 'Search',
	props: {
		value: { type: String, required: true },
		inputClass: { type: String, default: 'search-block__input' },
		iconButton: { type: Boolean, default: true },
		placeholder: { type: String, default: '' },
		searchForm: { type: String, default: '' },
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		focus() {
			this.$refs.input.focus();
		},
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/search";

	.search-form {
		position: relative;

		.search-block {
			height: 4rem;
			margin-bottom: 1rem;

			&__input {
				padding-left: 3rem;
				background: #F0F0F5;
				font-size: 1.1rem;
			}
		}

		.search-submit {
			position: absolute;
			top: 21px;
			color: #B8B9BF;
		}
	}

	.form-control:not(.custom-select) {
		&::placeholder {
			color: transparent;
		}

		@include media-breakpoint-up(md) {
			&::placeholder {
				color: #B8B9BF;
			}
		}
	}
</style>
