import checkScreenWidth from './checkScreenWidth';
import { BOOTSTRAP_BREAKPOINTS } from "~/utils/dictionaries";

const EFFECTS = {
	STICKY: 'sticky',
	BEFORE_STICKY: 'beforeSticky',
	AFTER_STICKY: 'afterSticky',
	AFTER_STICKY_BOTTOM: 'afterStickyBottom',
};

function findContainer(element, name) {
	if (!element.$parent) {
		return null;
	}
	if (element.$refs[name]) {
		return element.$refs[name];
	}
	return findContainer(element.$parent, name);
}

export default {
	mixins: [checkScreenWidth],
	data() {
		return {
			stickyKit: {
				mobileContainer: null,
				effect: null,
				parentRef: null,
				mobileParentRef: null,
				elementRef: null,
				offset_top_mobile: 56,
				customFixType: null,
				space: 0,
			},
		};
	},
	mounted() {
		this.getParentRef();
		this.getElementRef();
		if (this.stickyKit.parentRef && this.stickyKit.elementRef) {
			document.addEventListener('scroll', this.onScrollSticky);
			this.onScrollSticky();
		}
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.onScrollSticky);
	},
	watch: {
		screenWidth() {
			if (this.stickyKit.parentRef && this.stickyKit.elementRef) {
				this.makeDefault(true);
				this.onScrollSticky();
				this.checkStickyElementWidth();
			}
		},
	},
	computed: {
		checkScreenWidth() {
			return (this.stickyKit.from || 0) <= this.screenWidth
				&& this.screenWidth < (this.stickyKit.to || this.screenWidth + 1);
		},
		checkElementHeight() {
			return this.stickyKit.effect === EFFECTS.BEFORE_STICKY || this.stickyKit.effect === null;
		},
		offsetTop() {
			return this.screenWidth < BOOTSTRAP_BREAKPOINTS.md ? this.stickyKit.offset_top_mobile : this.stickyKit.offset_top;
		},
	},
	methods: {
		onScrollSticky(event) {
			if (this.checkScreenWidth) {
				// Динамическое переключение на мобильный отступ при изменении экрана
				if (this.stickyKit.effect === EFFECTS.STICKY && this.offsetTop !== this.stickyKit.offset_top) {
					this.stickyKit.elementRef.style.top = `${this.offsetTop}px`;
				}
				if (this.checkStickyEffect()) {
					this.stickyKit.effect = EFFECTS.STICKY;
					this.makeSticky();
				}
				if (this.checkBeforeSticky() && event) {
					this.stickyKit.effect = EFFECTS.BEFORE_STICKY;
					this.makeDefault();
				}
				if (this.checkAfterSticky() && this.stickyKit.effect) {
					this.stickyKit.effect = EFFECTS.AFTER_STICKY;
					if (!this.stickyKit.customFixType) this.makeStickyBottom();
				}

				if (this.checkAfterStickyBottom()) {
					this.stickyKit.effect = EFFECTS.AFTER_STICKY_BOTTOM;
					this.makeSticky();
				}
			}
		},
		getBorder(element, position) {
			return element.getBoundingClientRect()[position] - this.offsetTop;
		},
		checkStickyEffect() {
			return this.getBorder(this.stickyKit.elementRef, 'top') < 0
				&& !(this.getBorder(this.stickyKit.parentRef, 'bottom') - this.stickyKit.elementRef.offsetHeight < 0);
		},
		checkBeforeSticky() {
			return this.getBorder(this.stickyKit.elementRef.parentElement, 'top') >= 0
				&& this.stickyKit.effect !== EFFECTS.BEFORE_STICKY;
		},
		checkAfterSticky() {
			return this.getBorder(this.stickyKit.parentRef, 'bottom') - this.stickyKit.elementRef.offsetHeight < 0
				&& this.stickyKit.effect !== EFFECTS.AFTER_STICKY;
		},
		checkAfterStickyBottom() {
			return this.getBorder(this.stickyKit.parentRef, 'bottom') > this.stickyKit.elementRef.offsetHeight && this.stickyKit.effect === EFFECTS.AFTER_STICKY;
		},
		makeDefault(minimal = false) {
			if (!minimal) {
				this.stickyKit.elementRef.style.position = 'static';
				this.stickyKit.elementRef.style.top = '';
				this.stickyKit.elementRef.style.zIndex = '';
				this.stickyKit.elementRef.style.width = '';
				this.stickyKit.elementRef.parentElement.style.height = this.checkElementHeight ? '' : `${this.stickyKit.elementRef.parentElement.offsetHeight}px`;
			}
			this.stickyKit.space = this.stickyKit.elementRef.parentElement.offsetWidth
					- this.stickyKit.elementRef.offsetWidth;
		},
		makeSticky() {
			let stickyElement = this.screenWidth < BOOTSTRAP_BREAKPOINTS.md ? this.stickyKit.mobileParentRef : this.stickyKit.elementRef;
			const { customFixType } = this.stickyKit;
			const position = customFixType || 'fixed';

			if (!customFixType) {
				stickyElement = this.stickyKit.elementRef;
				stickyElement.style.width = `${stickyElement.parentElement.offsetWidth - this.stickyKit.space}px`;
				stickyElement.parentElement.style.height = `${stickyElement.offsetHeight}px`;
			}

			stickyElement.style.position = position;
			stickyElement.style.top = `${this.offsetTop}px`;
			stickyElement.style.zIndex = '9';
		},
		makeStickyBottom() {
			this.stickyKit.elementRef.style.position = 'absolute';
			this.stickyKit.elementRef.style.top = 'unset';
			this.stickyKit.elementRef.style.bottom = '0';
			this.stickyKit.elementRef.style.zIndex = '9';
			this.stickyKit.elementRef.style.width = `${this.stickyKit.elementRef.parentElement.offsetWidth - this.stickyKit.space}px`;
			this.stickyKit.elementRef.parentElement.style.height = `${this.stickyKit.elementRef.offsetHeight}px`;
		},
		getParentRef() {
			try {
				this.stickyKit.parentRef = findContainer(this, this.stickyKit.container);
			} catch {
				throw new Error('Не найден ref sticky-container');
			}
		},
		getElementRef() {
			try {
				this.stickyKit.elementRef = this.$refs[this.stickyKit.element];
				this.stickyKit.mobileParentRef = this.$refs[this.stickyKit.mobileContainer];
			} catch {
				throw new Error('Не найден ref sticky-element');
			}
		},
		checkStickyElementWidth() {
			const element = this.stickyKit.elementRef;
			if (element.style.width && element.offsetWidth !== element.parentElement.offsetWidth) {
				this.stickyKit.elementRef.style.width = `${this.stickyKit.elementRef.parentElement.offsetWidth}px`;
			}
		},
	},
};
