<template>
	<div class="mb-2">
		<div class="d-none d-lg-block">
			<b-button
				v-for="taxonomy in taxonomies"
				:key="taxonomy.id"
				variant="outline"
				class="btn-outline-primary mb-2 mr-2"
				:class="taxonomyData.currentTaxonomy === taxonomy.id && 'active'"
				@click="setTaxonomy(taxonomy)"
			>
				{{ taxonomy.name }}
			</b-button>
			<nuxt-link
				v-for="link in additionalLinks"
				:key="link.to"
				class="btn btn-outline-primary mb-2 mr-2"
				:to="link.to"
			>
				{{ link.title }}
			</nuxt-link>
			<b-button
				v-if="taxonomyData.currentTaxonomy"
				variant="outline"
				class="btn-outline-secondary mb-2 mr-2"
				@click="resetTaxonomy(taxonomy)"
			>
				Сбросить фильтр
			</b-button>
		</div>

		<div
			ref="sticky-element"
			class="select-inner d-block d-lg-none"
		>
			<button
				v-if="taxonomyData.currentTaxonomy"
				type="button"
				class="btn btn-outline-primary btn-sm icon-close btn-sm-close"
				@click="resetTaxonomy"
			/>
			<b-form-select
				:value="taxonomyData.currentTaxonomy"
				class="select-taxonomy"
				name="disciplines"
				@change="setMobileTaxonomy"
			>
				<template #first>
					<b-form-select-option :value="0" disabled>
						Выберите направление
					</b-form-select-option>
				</template>
				<b-form-select-option
					v-for="taxonomy in taxonomies"
					:key="taxonomy.id"
					:value="taxonomy.id"
				>
					{{ taxonomy.name }}
				</b-form-select-option>
				<b-form-select-option
					v-for="link in additionalLinks"
					:key="link.to"
					:value="link.to"
				>
					{{ link.title }}
				</b-form-select-option>
			</b-form-select>
		</div>
	</div>
</template>

<script>
import stickyMixin from '~/mixins/stickyMixin';

export default {
	name: 'MainTaxonomies',
	mixins: [stickyMixin],
	inject: ['taxonomyData', 'setFilter'],
	data() {
		return {
			selected: null,
			activeSelectFilter: null,
			additionalLinks: [
				{
					title: 'Коучинг, менторство, карьерное консультирование',
					to: '/centres/coaching/',
				},
				{
					title: 'Кастомизированные образовательные программы для компаний',
					to: '/corporate/',
				},
			],
			stickyKit: {
				element: 'sticky-element',
				container: 'sticky-container',
				offset_top: 56,
				offset_top_mobile: 56,
				to: 1260,
			},
		};
	},
	computed: {
		taxonomies() {
			return this.taxonomyData.taxonomies.filter((el) => +el.is_published === 1);
		},
		taxonomiesOptions() {
			return this.taxonomies.map((el) => ({
				text: el.name,
				value: el.id,
			}));
		},
	},
	watch: {
		'$route.query.taxonomy': {
			handler(taxonomy) {
				this.taxonomyData.currentTaxonomy = +taxonomy ?? null;
			},
			immediate: true,
		},
	},
	methods: {
		setMobileTaxonomy(val) {
			if (val > 0) {
				const [currentTaxonomy] = this.taxonomies.filter((el) => el.id === val);
				this.setTaxonomy(currentTaxonomy);
			} else if (val === 0) {
				this.resetTaxonomy();
			} else {
				this.$router.push(val);
			}
		},
		resetTaxonomy() {
			this.setFilter('taxonomyOptions', []);
			this.setFilter('page', 1);
			this.setFilter('search', null);
			this.taxonomyData.currentTaxonomy = null;
			this.setFilter('taxonomy', null);
		},
		setTaxonomy(taxonomy) {
			if (this.taxonomyData.currentTaxonomy === taxonomy.id) {
				this.resetTaxonomy(taxonomy);
			} else {
				this.setFilter('taxonomyOptions', this.taxonomyOptionsToFilters(taxonomy.taxonomyOptions));
				this.taxonomyData.currentTaxonomy = taxonomy.id;
				this.setFilter('taxonomy', taxonomy.id);
				this.setFilter('page', 1);
			}
		},
		taxonomyOptionsToFilters(taxonomyOptions) {
			return taxonomyOptions.reduce((newTaxonomyOptions, taxonomyOption) => {
				newTaxonomyOptions[taxonomyOption.id] = true;
				return newTaxonomyOptions;
			}, []);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/sk_variables";
@import "~/assets/styles/bootstrap/scss/functions";
@import "~/assets/styles/bootstrap/scss/variables";
@import "~/assets/styles/bootstrap/scss/mixins";
@import "~/assets/styles/components/layouts/event-search-header";

.select-inner {
	position: relative;
	z-index: 1002;
}

.icon-close {
	position: absolute;
	top: 50%;
	right: 2.7rem;
	transform: translateY(-50%);
	cursor: pointer;
}
.select-taxonomy {
	background-color: $white;
	padding: 1.2rem;
	height: auto !important;
	border: 1px solid $theme-light;
	background-position: right 1.2rem center;
	cursor: pointer;
}

.is_stuck {
	& select {
		border: none;
		border-bottom: 1px solid $theme-light;
	}
}

.reset-filter {
	text-align: center;
	font-weight: bold;
	padding: 0.5rem 0;
}
.taxonomy-item {
	padding-left: 1.5rem;
	padding-top: 1.5rem;
	padding-right: 0;
	border-top: 1px solid black;
	cursor: pointer;

	&--active {
		color: $primary;
	}
}
</style>
