<template>
	<nuxt-link :to="one(event.slug, event.typology_slug)">
		<article class="theme-white pt-4 pb-4 px-5">
			<div class="row justify-content-between">
				<div class="col-12 col-md-8 mb-2 mb-lg-4">
					<div class="d-flex flex-column justify-content-between">
						<div class="program_header">
							<div class="mb-md-4">
								<div class="announce_date_block align-items-center">
									<div class="announce_date_day">
										<span>{{ event.event_date | moment('D') }}</span>
										<!-- <a
											:href="'//sk.skolkovo.ru/api/calendar/event/' + event.id"
											aria-label="Добавить в календарь"
											class="add_announce"
										/> -->
									</div>
									<div class="announce_date_properties">
										<div class="announce_date_month">
											{{ event.event_date | moment('MMMM YYYY') }}
										</div>
										<div class="announce_date_time">
											{{ event.event_date | moment('dddd') }}
											{{ event.event_date | moment("HH:mm") }}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div class="title mb-3">
								<nuxt-link :to="one(event.slug, event.typology_slug)" class="title announce_title">
									{{ event.title }}
								</nuxt-link>
							</div>
							<div class="description">
								<p class="mb-lg-1 mb-md-4 subtitle">
									{{ event.subtitle }}
								</p>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="event.duration || event.project_format || event.language_name || event.project_terms || event.place || event.project_document_education"
					id="program-characteristics"
					class="col-12 col-md-4 mb-md-4 px-lg-3"
				>
					<ul class="program_characteristics list-inline">
						<li v-if="event.duration" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-calendar pr-2" />{{ event.duration }}
							</div>
						</li>

						<!-- <li v-if="event.project_format" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-system pr-2" />{{ event.project_format }}
							</div>
						</li> -->

						<li v-if="event.language_name" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-language pr-2" />{{ event.language_name }}
							</div>
						</li>

						<li v-if="event.project_terms" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-rub pr-2" />{{ event.project_terms }}
							</div>
						</li>

						<li v-if="event.place && event.place.ru" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-place pr-2" />{{ place.ru }}
							</div>
						</li>

						<li v-if="event.project_document_education" class="list-inline-item">
							<div class="item program_properties_list">
								<i class="icon icon-diploma pr-2" />{{ event.project_document_education }}
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div class="row program_content_footer justify-content-end align-self-end align-items-baseline pt-md-4 mt-md-0 mt-4">
				<!-- <ul class="tag_list d-none d-lg-flex">
					<li>
						<nuxt-link :to="'?type=' + event.type">
							{{ event.type_titles.title_default }}
						</nuxt-link>
					</li>
					<li v-for="taxonomy in event.taxonomies" :key="taxonomy.id">
						<nuxt-link>
							{{ taxonomy.name }}
						</nuxt-link>
					</li>
				</ul> -->
				<nuxt-link :to="one(event.slug, event.typology_slug)" class="btn btn-primary col-12 col-md-4">
					Детали и регистрация
				</nuxt-link>
			</div>
		</article>
	</nuxt-link>
</template>

<script>
export default {
	name: 'ContentItem',
	props: {
		event: {
			type: Object,
			required: true,
		},
	},
	methods: {
		one(slug, typology, query, ...rest) {
			return this.$url.event(slug, typology, { query, ...rest });
		},
		many(query, ...rest) {
			// TODO: change when page moved
			return { name: 'sidebar-filter-events', query, ...rest };
		},
	},
};
</script>

<style lang="scss" scoped>
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.announce_date {
	&_block {
		display: flex;
		flex-direction: row;
		margin-bottom: 1.5rem;
		padding: 1.5rem 0 1rem;
		border: 0;
		height: 100%;
		justify-content: center;

		@include media-breakpoint-up(sm) {
			padding: 0;
		}

		@include media-breakpoint-down(xs) {
			.col {
				width: auto;
				flex-grow: 0;
			}
		}
	}

	&_day {
		display: flex;
		position: relative;
		align-items: flex-start;

		font-size: 3.2rem;
		font-weight: 700;
		line-height: 1;

		margin-top: -0.4rem;
		padding-right: .75rem;

		a {
			margin-top: .85rem !important;
		}

		.add_announce {
			position: relative;
			margin-top: 1.5rem;
			left: 0.75rem;
			letter-spacing: 0;
			text-decoration: none;
			color: $control-link-color;

			&:hover {
				color: $white;
			}

			&::before {
				content: "\e921";
				display: block;
				font-family: 'sk_icons';
				line-height: 1.5rem;
				font-size: 1.5rem;
			}
		}
	}

	&_properties {
		display: flex;
		flex-direction: column;
	}

	&_month {
		font-size: 1.5rem;
		font-weight: bold;
		line-height: 1;
	}

	&_time {
		font-size: 1rem;
		line-height: 20px;
		color: #949ABA;
	}
}

.subtitle {
	font-weight: 400;
	font-size: 1rem;
}

.program {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 100%;
	padding: 0 0 $grid-gutter-width;
	border: 1px solid;

	min-height: 17rem; // 504px (rem=24)

	&.theme-default {
		.theme-light & {
			border: 0;
		}
	}

	&_characteriscics {
		padding: 8px;
		height: 100%;

		.item {
			position: relative;
			line-height: 1.2;
			padding: 0 0 0 1.5rem;
			margin: 0.5rem 0;
			font-weight: 400;

			.icon {
				position: absolute;
				left: 0;
				top: 0.15rem;
			}
		}
	}

	&_header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 0 0;
		padding: 0;
	}

	&_type {
		margin: 0rem 1rem 1rem 0;
		color: $control-link-color;

		&:hover {
			color: $link-hover-color;
		}
	}

	&_properties {
		list-style: none;
		padding: 0;

		li {
			position: relative;
			padding: 0 0 0 1.5rem;
			line-height: 1.2;
			margin: 0 0 0.5rem;

			&::before {
				position: absolute;
				display: block;
				font-family: "sk_icons";
				left: 0;
			}
		}
	}

	&_language {
		&::before {
			content: "\e907";
		}
	}

	&_system {
		&::before {
			content: "\e906";
		}
	}

	&_age {
		&::before {
			content: "\e905";
		}
	}

	&_diploma {
		&::before {
			content: "\e91d";
		}
	}

	&_place {
		&::before {
			content: "\e90b";
		}
	}

	&_tags {
		&::before {
			content: "\e94b";
		}
	}

	&_info {
		&::before {
			content: "\e94c";
		}
	}

	&_price {
		&::before {
			content: "\e94d";
		}
	}

	&_content {
		padding: 0 $grid-gutter-width 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;

		.title {
			display: inline-block;
			margin-bottom: 1.2rem;
		}

		&_footer {
			position: relative;
			display: flex;
			justify-content: space-between;

			padding-top: $grid-gutter-width;
			border-top: 1px solid #F0F0F5;
		//	margin: 0.8rem 0 0 0;
		}
	}

	.add_program {
		text-decoration: none;
		color: $control-link-color;

		&:hover {
			color: $link-hover-color;
		}

		&::before {
			content: "\e921";
			display: block;
			font-family: "sk_icons";
			line-height: 1.5rem;
			font-size: 1.5rem;
		}
	}

	&.ext {
		.preview {
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			background: red;
			padding-bottom: 56.25%;
		}
	}
}

.tag_list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: inline-flex;
	flex-wrap: wrap;

	li {
		display: inline-block;
		margin: 0;
		padding: 0;

		&::after {
			content: ',';
		}

		&:last-child {
			&::after {
				display: none;
				content: '';
			}
		}

		a {
			display: inline-flex;
		}
	}
}

.tag_list {
	li {
		margin: 0 0.3rem 0 0;
		padding: 0;
		font-size: 90%;
	}
}

.details {
	a {
		color: #FFFFFF !important;
	}

	.theme-white a:not(.btn):not(.title) {
		color: #FFFFFF !important;
	}

	.white {
		color: #FFFFFF !important;
	}

}

.list-inline-item {
	display: block;
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0px;
	}
}

#program-characteristics {
	border: 1px solid #F0F0F5;
	border-radius: 0px;
	height: fit-content;
	padding: 1rem .5rem;
}

</style>
