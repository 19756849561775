<template>
	<header class="header">
		<div class="header_wrapper">
			<top-menu-new :fetched-data="fetchedData" />
		</div>
	</header>
</template>

<script>
import TopMenuNew from '~/components/layouts/_partials/TopMenuNew.vue';
import IssueMenu from '~/components/layouts/_partials/IssueMenu.vue';

export default {
	name: 'ShortHeader',
	components: {
		TopMenuNew,
		IssueMenu,
	},
	props: {
		fetchedData: { type: Object, default: () => ({}) },
	},
};

</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/layouts/short-header-panel";
</style>
