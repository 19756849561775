import { render, staticRenderFns } from "./MainTaxonomies.vue?vue&type=template&id=113ebb8c&scoped=true"
import script from "./MainTaxonomies.vue?vue&type=script&lang=js"
export * from "./MainTaxonomies.vue?vue&type=script&lang=js"
import style0 from "./MainTaxonomies.vue?vue&type=style&index=0&id=113ebb8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "113ebb8c",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BFormSelectOption, BFormSelect} from 'bootstrap-vue'
    installComponents(component, {BButton, BFormSelectOption, BFormSelect})
    

export default component.exports