<template>
	<ul id="panel_useful" class="programs_list">
		<li v-for="item in appData.events.data" :key="item.id">
			<content-item :event="item" />
		</li>
	</ul>
</template>

<script>
import ContentItem from '~/components/navigator-v2/body/content/partials/ContentItem.vue';

export default {
	name: 'ContentItems',
	components: { ContentItem },
	inject: ['appData'],
};
</script>

<style lang="scss" scoped>
@import "assets/styles/sk_variables";
// For use bootstrap vars and mixins inside
@import "assets/styles/bootstrap/scss/functions";
@import "assets/styles/bootstrap/scss/variables";
@import "assets/styles/bootstrap/scss/mixins";

.programs {
	&_list {
		list-style: none;
		margin-top: 0;
		margin-bottom: 0;
		padding: 0;

		& > li {
			margin-bottom: $grid-gutter-width;
		}
	}
}
</style>
