<template>
	<div class="navigator-with-prices">
		<nuxt-link
			v-for="event in appData.events.data"
			:key="event.id"
			:to="one(event.slug, event.typology_slug)"
			class="navigator-with-prices__item"
		>
			<div class="navigator-with-prices__item-title">
				{{ event.title }}
			</div>

			<div class="navigator-with-prices__price-group">
				<div
					class="navigator-with-prices__full-price"
				>
					<span v-if="event.price_with_personal_discount">Полная стоимость: </span>
					<span
						:class="{
							'navigator-with-prices__full-price_crossed': event.price_with_personal_discount,
							'navigator-with-prices__full-price_bold': !event.price_with_personal_discount,
						}"
					>
						{{ $rub(event.price) }}
					</span>
				</div>

				<div
					v-if="event.price_with_personal_discount"
					class="navigator-with-prices__discount-price"
				>
					С учетом списания баллов: {{ $rub(event.price_with_personal_discount) }}
				</div>
			</div>
		</nuxt-link>
	</div>
</template>

<script>
export default {
	name: 'ContentItem',

	inject: ['appData'],

	methods: {
		one(slug, typology, query, ...rest) {
			return this.$url.event(slug, typology, { query, ...rest });
		},

		many(query, ...rest) {
			return { name: 'sidebar-filter-events', query, ...rest };
		},
	},
};
</script>
