<template>
	<div class="search-header theme-light">
		<div class="panel_header">
			<div id="scroll-on-page-change" class="container">
				<h1 id="events-scroll-to">
					{{ title }}
				</h1>
				<div>
					<div class="d-flex justify-content-between align-items-baseline flex-wrap">
						<search-line-menu :links="links" class="mb-3" />
					</div>
					<search
						:value="appData.filters.search"
						:search-form="'search-form'"
						:placeholder="'Введите название программы, которая вас интересует'"
						class="mb-4"
						@input="handlerInput($event)"
					/>
				</div>

				<main-taxonomies />
			</div>
		</div>
	</div>
</template>

<script>
import MainTaxonomies from '~/components/navigator-v2/header/MainTaxonomies.vue';
import links from '@/pages/navigator/links';
import SearchLineMenu from '@/components/layouts/_partials/SearchLineMenu.vue';
import Search from '@/components/refactor/Search.vue';

export default {
	name: 'NavigatorHeader',
	components: { MainTaxonomies, SearchLineMenu, Search },
	inject: ['appData', 'setFilter'],
	data() {
		return {
			links,
			title: null,
		};
	},
	created() {
		switch (this.$route.name) {
		case 'navigator-programmes': this.title = 'Навигатор программ'; break;
		case 'navigator-events': this.title = 'Навигатор мероприятий'; break;
		case 'navigator-about-pogrammes': this.title = 'О программах'; break;
		default: break;
		}
	},
	methods: {
		handlerInput(value) {
			this.setFilter('search', value);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/layouts/event-search-header";

.search-header {
	background-color: #FFFFFF;
	margin-bottom: 0;
	padding-bottom: 1rem;
}

.panel_header {
	margin-bottom: 0;
}

.dropdown-toggle {
	white-space: normal;
	cursor: pointer;

	&:focus {
		outline: none !important;
	}

	&::after {
		//			display: none;
	}
}
</style>
